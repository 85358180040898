import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import swal from "sweetalert";
import ColumnChart from '../../hoc/ColumnChart';
import {
  accountID,
  lookups,
  nextPaymentDate,
  nextPaymentValue,
  seaglassURL,
  loginCount
} from '../../constants';
import {
  strToGiveMonthName,
  handleResponseCode,
  showAlert,
  filterLastTwelveMonthsData,
  sortObjArrayDateWise,
  handleException,
  currencyFormat,
  logScreenView,
} from "../../utility";
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import BalanceSquare from '../../hoc/BalanceSquare';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import ImportantInformation from '../../hoc/ImportantInformation';

let colorsArr = [];

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      sitesList: [],
      accountDetail: [],
    };
  }

  componentDidMount() {
    // Api call for GetPaymentDetailByAccountId
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/payments`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data.length > 0) {
            let payments = response.response.data;
            payments = filterLastTwelveMonthsData(payments, 'paymentdate');
            payments = sortObjArrayDateWise(payments, 'paymentdate');
            this.callNextPaymentPlan(payments); // Call an Api For Next Payment Bar
            // this.callNextPaymentPlan(response.response.data); // Call an Api For Next Payment Bar
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });

    this.getMeterPoints();
    this.getNextInvoiceDate();
    if (this.checkMeterReadAlert()) {
      this.showMeterReadAlert();
    }
    POSTSeaglass(
      `${seaglassURL}sgBill/accounts/${localStorage.getItem(accountID)}`
    ).then((response) => {
      if (handleResponseCode(response)) {
        localStorage.setItem('companyName', response.response.data.name);
        // eslint-disable-next-line
        this.state.accountDetail = response.response.data;
      }
    });
  }

  // Api call for Payment Plans
  callNextPaymentPlan = (barArray) => {
    // let nextPayData = null;
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/paymentplans`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.response.data);
          if (response.response.data.length > 0) {
            this.findNextPaymentDate(response.response.data);
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }

        const barData = [];
        colorsArr = [];
        // eslint-disable-next-line max-len
        // Create an chartArray with only Month Name and Value from GetPaymentDetailByAccountId Response
        barArray.forEach((item) => {
          const monthName = strToGiveMonthName(item.paymentdate);

          if (barData != null) {
            const index = barData.findIndex((items) => items.x === monthName);
            if (item.paymentstatus !== "Failed" && item.paymentstatus !== "Cancelled") {
              if (index !== -1) {
                  barData[index].y += item.value;
              } else {
                const data = {
                  x: monthName,
                  y: item.value,
                };
                barData.push(data);
                colorsArr.push("#009b4a"); // Make an colorArray with Green Color for all data
              }
            }
          }
        });
        this.setState({ chartData: barData });
        console.log('colors :', colorsArr);
        console.log('ChartData :', this.state.chartData);
      })
      .catch((error) => {
        handleException(error);
      });
  };

  checkMeterReadAlert = () => {
    let isSmartMeter = false;
    let loginCountVal = localStorage.getItem(loginCount);
    let submittedReadInLast28Days = false;

    // Check for smart meter
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}/meterpoints`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.response.data);
          if (response.response.data.length > 0) {
            response.response.data.forEach((site) => {
              console.log("siteList meteringtypeid:");
              console.log(site.meteringtypeid);
              if (site.meteringtypeid === 3) {
                isSmartMeter = true;
                console.log('IS a smart meter');
              }
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
    // Check last time a meter read was submitted
    const meterpointsurl = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}/meterpoints`;
    POSTSeaglass(meterpointsurl)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data && response.response.data.length > 0) {
            response.response.data.forEach((meterpoint) => {
              if (meterpoint.id) {
                const dailyrevenuesurl = `${seaglassURL}sgBill/meterpoints/${meterpoint.id}/dailyrevenues`;
                POSTSeaglass(dailyrevenuesurl)
                  .then((nextResponse) => {
                    if (handleResponseCode(nextResponse)) {
                      if (nextResponse.response.data) {
                        if (nextResponse.response.data.length > 0) {
                          submittedReadInLast28Days = true;
                        }
                      }
                    } else if (nextResponse && nextResponse.message) {
                             showAlert("Error", nextResponse.message);
                           }
                  })
                  .catch((error) => {
                    handleException(error);
                  });
              }
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });


    // Only return true if they don't have a smart meter and they haven't had a meter read in
    // the last 28 days and if their app login count is less than 3
    if (isSmartMeter === false && submittedReadInLast28Days === false && loginCountVal < 3) {
      return true;
    }
    return false;
  };

  showMeterReadAlert = () => {
    const el = document.createElement("div");
    el.innerHTML =
      'Please send us a meter read now';
    swal({
      title: 'Don’t overpay on your bill',
      content: el,
      buttons: {
        cancel: true,
        confirm: 'Send meter reading',
      },
    }).then((value) => {
      if (value === true) {
        if (window.cordova) {
          window.cordova.InAppBrowser.open(
            'https://www.valdaenergy.com/meter-read',
            '_blank',
            'location=no'
          );
        } else {
          window.open('https://www.valdaenergy.com/meter-read', '_system');
        }
      }
    });
  };

  // Api for MeterPoint
  getMeterPoints = () => {
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}/meterpoints`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.response.data);
          if (response.response.data.length > 0) {
            this.setState({
              sitesList: response.response.data,
            });
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  getNextInvoiceDate = () => {
     // Their bill will be in the future, so we need to check for this
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      // eslint-disable-next-line comma-dangle
      accountID
    )}/invoices`;
    POSTSeaglass(url)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data) {
            console.log(response.response.data);
            const todayDate = new Date();
            response.response.data.forEach((invoice) => {
              if (new Date(invoice.duedate) >= todayDate) {
                this.setState({
                  nextCollectionDateDisplay:
                    invoice.duedate,
                });
              }
            });
          }
        } else if (response && response.message) {
          showAlert("Error", response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Find next payment date
  findNextPaymentDate = (responseArr) => {
    const todayDate = new Date();
    const arrNextPayDates = [];

    if (responseArr.length <= 0) {
      localStorage.removeItem(nextPaymentDate);
      localStorage.removeItem(nextPaymentValue);
    }

    responseArr.forEach((item) => {
      const effectiveDate = new Date(item.effectivefrom);
      console.log('effectiveDate : ', effectiveDate);

      // Find PaymentFrequency Monthly or Weekly
      const paymentFrequencyIndex = lookups.paymentfrequency.findIndex(
        (status) => status.id === item.paymentfrequencyid
      );
      let paymentFrequency = '';
      if (paymentFrequencyIndex !== -1) {
        paymentFrequency =
          lookups.paymentfrequency[paymentFrequencyIndex].value;
      }
      console.log('paymentfrequencyid : ', paymentFrequency);

      if (effectiveDate.getFullYear() < todayDate.getFullYear()) {
        effectiveDate.setFullYear(
          todayDate.getFullYear(),
          todayDate.getMonth() - 1,
          todayDate.getDate()
        );
      }
      let newDate = effectiveDate;
      if (newDate.getTime() !== todayDate.getTime()) {
        while (newDate < todayDate) {
          newDate = this.addDaysInEffectiveDate(paymentFrequency, newDate);
        }
      }

      const paymentData = {
        nextDate: newDate,
        amount: item.value,
      };
      arrNextPayDates.push(paymentData);
    });
    this.setNextPaymentDate(arrNextPayDates);
  };

  // Add a Days in date according to its PaymentFrequency
  addDaysInEffectiveDate = (paymentFrequency, effectiveDate) => {
    console.log('effectiveDate: ', effectiveDate);
    if (paymentFrequency === 'Weekly') {
      return new Date(effectiveDate.setDate(effectiveDate.getDate() + 7));
      // return effectiveDate.setDate(effectiveDate + 7 * 24 * 60 * 60 * 1000);
    }
    // It gives a month from 0 index so we have to add 2 for next month
    const i = effectiveDate.getMonth() + 1;
    console.log('Month : ', i);
    return new Date(effectiveDate.setMonth(i));

    // return (effectiveDate);
  };

  // setNextPaymentDate And Amount in LocalStorage
  setNextPaymentDate = (arrDates) => {
    console.log('arrDates : ', arrDates);
    const now = new Date();
    let closest = Infinity;
    let amt = 0;
    arrDates.forEach((d) => {
      const date = new Date(d.nextDate);
      if (date >= now && (date < new Date(closest) || date < closest)) {
        closest = date;
        amt = d.amount;
      }
    });
    localStorage.setItem(nextPaymentDate, closest);
    localStorage.setItem(nextPaymentValue, amt);
    console.log('Next Pay date is : ', closest);
  };

  handleViewInvoice = () => {
    this.props.goToInvoice(false);
  };

  render() {
    console.log('this.props.accountDetail: ', this.state.accountDetail);
    logScreenView('Home');
    const { accountDetail } = this.state;
    const advancedBillingID = [5, 4];
    let accountType = 'standard';
    let varyMessage = '';
    let paymentSquareContent = '';
    let accountBalance = '';
    let daysOfEnergyLeft = 0;
    let debitCreditText = 'In Debit';
    let bgColorDay = '#B6412B';
    let bgColorAmount = '#B6412B';
    let nextInvoiceDate = 'Unknown';
    let nextInvoiceDateDisplay = '';

    if (accountDetail && accountDetail.livebalance) {
      accountBalance = accountDetail.livebalance;
      
      if (
        // eslint-disable-next-line operator-linebreak
        accountDetail.livebalancetext &&
        accountDetail.livebalancetext.indexOf("DR") === -1
      ) {
        bgColorAmount = "#009b4a";
        debitCreditText = "In Credit";
      }
      if (accountType === 'advanced' && accountDetail.balances.duebal > 0) {
        bgColorAmount = "#B6412B";
      }
      if (
        accountDetail.dailybalance &&
        accountDetail.dailybalance.dailyspend
      ) {
        // eslint-disable-next-line operator-linebreak
        console.log('d371');
        console.log(accountBalance / accountDetail.dailybalance.dailyspend);

        daysOfEnergyLeft =
          accountBalance / accountDetail.dailybalance.dailyspend;
            // eslint-disable-next-line radix
        daysOfEnergyLeft = parseInt(daysOfEnergyLeft) * -1;
        console.log(daysOfEnergyLeft);
        if(daysOfEnergyLeft < 1) {
          daysOfEnergyLeft = 0;
        }

      }
    }

    if (daysOfEnergyLeft >= 14) {
      bgColorDay = "#009b4a";
    } else if (daysOfEnergyLeft > 7) {
      bgColorDay = "#FFCA2D";
    } else if (daysOfEnergyLeft > 3) {
      bgColorDay = "#B6412B";
    }

    if(accountDetail && accountDetail.directdebit) {
      // They're a direct debit user, so check for their latest bill collection date to display
      paymentSquareContent = (
        <>
          <BalanceSquare
            bgColor="#009b4a"
            clickEvent={this.props.goToPayments}
            insideText={(
              <div>
                <div>Next collection</div>
                <div
                  className="bigText boldText"
                  style={{
                    fontSize: "1.5em",
                    paddingBottom: "2px",
                    paddingTop: "5px",
                  }}
                >
                  {this.state.nextCollectionDateDisplay}
                </div>
                <div>via Direct Debit</div>
                <div className="action" style={{ backgroundColor: "#009b4a" }}>
                  View payments
                </div>
              </div>
            )}
          />
        </>
      );
    } else if (accountDetail && advancedBillingID.includes(accountDetail.paymentprofileid)) {
      accountType = 'advanced';
      if (accountDetail.schedulerdata.billdate !== "") {
        nextInvoiceDate = accountDetail.schedulerdata.billdate;
        nextInvoiceDate = new Date(accountDetail.schedulerdata.billdate);
        nextInvoiceDateDisplay = `${nextInvoiceDate.getDate()}-${
          nextInvoiceDate.getMonth() + 1
        }-${nextInvoiceDate.getFullYear()}`;
      }
      paymentSquareContent = (
        <>
          <BalanceSquare
            bgColor="#009b4a"
            clickEvent={this.props.goToPayments}
            insideText={
              <div>
                <div>Next invoice</div>
                <div className="bigText boldText" style={{fontSize: "1.5em",paddingBottom: "27px",paddingTop: "5px"}}>{nextInvoiceDateDisplay}</div>
                <div className="action" style={{ backgroundColor: "#009b4a" }}>
                  View payments
                </div>
              </div>
            }
          />
        </>
      );
    } else {
      varyMessage = (<><div className="centerText">
        <span>*</span>
        Could vary if usage changes
      </div></>);
      paymentSquareContent = (
        <>
          <BalanceSquare
            bgColor={bgColorDay}
            clickEvent={this.props.goToPayment}
            insideText={
              <div>
                <div>Days left</div>
                <div className="bigText boldText">
                  {daysOfEnergyLeft}
                  <span>*</span>
                </div>
                <div>Days of energy left</div>
                <div
                  className="action"
                  style={{ backgroundColor: bgColorAmount }}
                >
                  Make Payment
                </div>
              </div>
            }
          />
        </>
      );
    }

    accountBalance = Math.abs(accountBalance);
    accountBalance = currencyFormat(accountBalance);
    localStorage.setItem('accountBalance', accountBalance);
    localStorage.setItem('debitCreditText', debitCreditText);

    let showChart = '';
    if (this.state.chartData.length) {
      showChart = (
        <ColumnChart colorsList={colorsArr} chartData={this.state.chartData} />
      );
    }

    let importantInfo = '';

    // Important Information: Phase 1 – energy is not live yet – all types of customers
    this.state.sitesList.forEach((site) => {
      if (site.registrationstatusid !== 2) {
        const infoElement = (
          <div>
            <div>Thank you for making your first payment.</div>
            <div>
              <span className="boldText">What happens next?</span>
              <span>
                {' '}
                Please look out for your Welcome Pack and confirmation of energy
                supply start date by email. If required, we will contact you to
                arrange a smart meter installation date soon.
              </span>
            </div>
          </div>
        );

        importantInfo = <ImportantInformation displayText={infoElement} />;
      }
    });

    // Important Information: Phase 2 -
    // energy is now live, they have a dumb meter and smart meter isn’t installed yet.
    if (importantInfo === '') {
      this.state.sitesList.forEach((site) => {
        if (site.meteringtypeid !== 3) {
          const infoElement = (
            <div>
              <div>We are now supplying you your energy.</div>
              <div>
                <span className="boldText">What happens next?</span>
                <span>
                  {' '}
                  If do you not have a smart meter installation date, please
                  contact us to arrange one as soon as possible.
                </span>
              </div>
            </div>
          );

          importantInfo = <ImportantInformation displayText={infoElement} />;
        }
      });
    }

    // Important Information: Phase 3 - energy is now live, they have a smart meter
    if (importantInfo === '') {
      this.state.sitesList.forEach((site) => {
        if (site.meteringtypeid === 3) {
          const infoElement = (
            <div>
              If you have any questions or need help using the app, please view
              our FAQs, or contact us using the channels detailed in the Contact
              Us section.
            </div>
          );

          importantInfo = <ImportantInformation displayText={infoElement} />;
        }
      });
    }

    return (
      <div>
        <div className="dontShowOnDesktop">
          <CustomTitlebar
            menuClick={this.props.menuClick}
            showMenuBtn
            titleText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div style={{ fontSize: '1.3em' }}>
                Welcome
              </div>
            }
          />
        </div>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="marginTopMinus"
        >
          <BalanceSquare
            bgColor={bgColorAmount}
            clickEvent={this.handleViewInvoice}
            insideText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div>
                <div>Account balance</div>
                <div className="bigText boldText">{`£${accountBalance}`}</div>
                <div>{debitCreditText}</div>
                <div className="action" style={{backgroundColor: bgColorAmount}}>View Invoices</div>
              </div>
            }
          />
          {paymentSquareContent}
        </Grid>
        <div className="scrollingContainer homePageScroller">
          <div className="paddingBottomOne">
            <div
              className="dontShowOnMobile"
              style={{ width: '50%', height: '1em', float: 'left' }}
            >
              {' '}
            </div>
            {varyMessage}
          </div>

          {showChart}
          {importantInfo}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

export default connect(mapStateToProps)(HomePage);

HomePage.propTypes = {
  accountDetail: PropTypes.objectOf(PropTypes.object),
  menuClick: PropTypes.func,
  goToInvoice: PropTypes.func.isRequired,
  goToPayment: PropTypes.func.isRequired,
  goToPayments: PropTypes.func.isRequired,
};

HomePage.defaultProps = {
  accountDetail: {},
  menuClick: false,
};
