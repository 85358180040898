/* eslint-disable import/no-mutable-exports */
// Environment
const env = 'dev'; // dev or prod

// Base URL
let appUrl = 'https://devapp.valdaenergy.com';
let valdaUrl = 'https://account.dev.valdaenergy.com/api/1.0/';
let ddSignupURL = 'https://account.valdaenergy.com/dd-signup?account_id=';
let meterReadSubmissionURL = 'https://account.dev.valdaenergy.com/submit-meter-read?account_id=';
let meterReadWebsiteSubmissionURL = 'https://dev.valdaenergy.com/submit-a-meter-read?account_id="';
let seaglassURL = `${valdaUrl}seaglass/`;
let notificationUrl =
  'https://wu6xv1lklf.execute-api.eu-west-2.amazonaws.com/test/windcave/notify';

const seaglassUsername = 'valdaapp';
const seaglassPassword = '9BC3T0B$oV2GAtcY';
const seaglassBasicAuth = `Basic ${btoa(
  `${seaglassUsername}:${seaglassPassword}`
)}`;

let debugOTP = 1;
if (env === 'prod') {
  // console.log = () => {};
  appUrl = "https://app.valdaenergy.com";
  valdaUrl = "https://account.valdaenergy.com/api/1.0/";
  ddSignupURL = "https://account.valdaenergy.com/dd-signup?account_id=";
  meterReadSubmissionURL = "https://account.valdaenergy.com/submit-meter-read?account_id=";
  meterReadWebsiteSubmissionURL = "https://www.valdaenergy.com/submit-a-meter-read?account_id=";
  seaglassURL = `${valdaUrl}seaglass/`;
  notificationUrl =
    'https://gljpew7kdl.execute-api.eu-west-2.amazonaws.com/production/public/windcave/notify';

  debugOTP = 0;
}
export {
  appUrl,
  valdaUrl,
  seaglassURL,
  ddSignupURL,
  meterReadSubmissionURL,
  meterReadWebsiteSubmissionURL,
  seaglassBasicAuth,
  notificationUrl,
  debugOTP,
};

// Constants
export const accountID = 'accountID';
export const multipleAccounts = 'multipleAccounts';
export const accountsList = 'accountsList';
export const encryptedAccountID = 'encryptedAccountID';
export const refreshToken = 'refreshToken';
export const token = 'token';
export const deviceId = 'deviceId';
export const loginCount = 'loginCount';
export const nextPaymentDate = 'nextPaymentDate';
export const nextPaymentValue = 'nextPaymentValue';
export const accountBalance = 'accountBalance';
export const debitCreditText = 'debitCreditText';
export const isRefreshTokenApiCall = 'isRefreshTokenApiCall';
export const pinCode = 'pinCode';

export const lookups = {
  paymentfrequency: [
    {
      id: 1,
      value: 'Monthly',
    },
    {
      id: 2,
      value: 'Weekly',
    },
  ],
  paymenttype: [
    {
      id: 1,
      value: 'Direct Debit',
    },
    {
      id: 2,
      value: 'BACS',
    },
    {
      id: 3,
      value: 'CHAPS',
    },
    {
      id: 4,
      value: 'Cheque',
    },
    {
      id: 5,
      value: 'Card',
    },
    {
      id: 6,
      value: 'Paypal',
    },
    {
      id: 7,
      value: 'Apple Pay',
    },
    {
      id: 8,
      value: 'Internal Transfe',
    },
    {
      id: 9,
      value: 'External Transfer',
    },
  ],
  paymentstatus: [
    {
      id: 1,
      value: 'Scheduled',
    },
    {
      id: 2,
      value: 'Claimed',
    },
    {
      id: 3,
      value: 'Cleared',
    },
    {
      id: 4,
      value: 'Failed',
    },
    {
      id: 5,
      value: 'Cancelled',
    },
    {
      id: 6,
      value: 'Requested',
    },
  ],
  invoicelinetype: [
    {
      id: 1,
      value: 'Consumption Charge',
    },
    {
      id: 2,
      value: 'Daily Charge',
    },
    {
      id: 3,
      value: 'CCL',
    },
    {
      id: 4,
      value: 'Availability Charge',
    },
    {
      id: 5,
      value: 'VAT',
    },
    {
      id: 6,
      value: 'Other',
    },
    {
      id: 7,
      value: 'Export',
    },
  ],
  utility: [
    {
      id: 1,
      value: 'Electricity',
    },
    {
      id: 2,
      value: 'Gas',
    },
  ],
  invoicestatus: [
    {
      id: 1,
      value: 'Being prepared',
    },
    {
      id: 2,
      value: 'Complete',
    },
  ],
  registrationstatus: [
    {
      id: 1,
      value: 'Pending',
    },
    {
      id: 2,
      value: 'Live',
    },
    {
      id: 3,
      value: 'Dead',
    },
    {
      id: 4,
      value: 'Accepted',
    },
    {
      id: 5,
      value: 'Abandoned',
    },
  ],
  meteringtype: [
    {
      id: 1,
      value: 'Manual',
    },
    {
      id: 2,
      value: 'AMR',
    },
    {
      id: 3,
      value: 'Smart',
    },
    {
      id: 4,
      value: 'Half Hourly',
    },
    {
      id: 5,
      value: 'Daily',
    },
    {
      id: 6,
      value: 'Export',
    },
  ],
};
export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
